import { Popper, TextField } from '@mui/material';
import styled from 'styled-components';
import { Autocomplete, baseColors, shadows, ToggleButtonGroup, } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { NoDataFallback } from '../no-data-fallback';
export const StyledToggleButtonGroup = styled(ToggleButtonGroup) `
  height: 30px;
`;
export const StyledDrawerWrapper = styled.div `
  position: relative;
  width: 50%;
  ${({ open }) => !open && 'display: none'};
`;
export const StyledDrawer = styled.div `
  position: relative;
  width: 100%;
  overflow: ${({ isHistoryOpened }) => (isHistoryOpened ? 'hidden' : 'auto')};
  background-color: ${baseColors.white};

  ${({ $offsetY }) => $offsetY && `
    height: calc(100vh - 2rem - ${$offsetY}px);
  `};

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
  box-shadow: ${shadows.backgroundCard};
`;
export const StyledPanelRow = styled.div `
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
  width: ${({ $openModal }) => ($openModal ? '630px' : 'auto')};
  padding: ${({ theme, $openModal }) => ($openModal ? `${theme.spacing(1)} 0rem` : `${theme.spacing(1)} ${theme.spacing(4)}`)};
  background-color: ${baseColors.white};

  ${({ $sticky }) => typeof $sticky === 'number' && `
    position: sticky;
    top: ${$sticky}rem;
    z-index: ${$sticky === 0 ? 3 : 1};
  `}

  ${({ $lastSticky }) => $lastSticky && `
    display: unset;
    box-shadow: 0px 4px 4px 0px #081E4214;
    margin-bottom: 8px;
    font-size: 0.75rem;
    color: ${baseColors.primaryBlueMed600};
    z-index: 1;

    span {
      font-weight: 700;
    }

  `}

  &.preview-panel--config-row {

    .MuiInputBase-root {
      flex: 1;
    }
  }
`;
export const StyledHideButton = styled(Button) `
  &.MuiButton-root {
    color: ${({ theme }) => theme.palette.error.dark};
    font-size: 14px;
  }

  &.MuiButton-text {
    &:hover {
      color: ${({ theme }) => theme.palette.error.dark};
      background-color: ${({ theme }) => theme.palette.error.light};
    }
  }
`;
export const StyledPreviewText = styled.div `
  cursor: default;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  color:  ${({ theme }) => theme.palette.accent.main};
`;
export const StyledFullScreenPreviewButtonWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const StyledSearchQueryInput = styled(TextField) `
  && {
    flex: 1;

    .MuiInputBase-root {
      max-height: ${({ theme }) => theme.spacing(4.5)};
    }

    fieldset {
      inset: 0px;
      height: ${({ theme }) => theme.spacing(4.5)};
    }

    fieldset > legend {
      display: none;
    }

    label {
      transform: translate(40px, 7px) scale(1);
      background-color: white;
      z-index: 2;
      padding-left: 5px;
      padding-right: 5px;
    }

    label.Mui-focused {
      transform: translate(14px, -10px) scale(0.75);
    }
    label.MuiFormLabel-filled {
       transform: translate(14px, -10px) scale(0.75);
     }

  }
`;
export const StyledSearchBrowseInput = styled(Autocomplete) `
  flex: 1 1 0;

  fieldset {
    inset: 0px 0px 0px;
    height: ${({ theme }) => theme.spacing(4.5)};
  }

  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}

  &&&& fieldset:first-of-type {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    &:hover {
      // temporarily matched the colour of the MUI outlined input
      // both will be changed when adhering to the new design guidelines
      border-color: #000000de;
    }
  }

  &&& .MuiInputBase-root {
    min-height: 0px;
    padding: ${({ theme }) => `0 0 0 ${theme.spacing(1)}`};
  }

  && {
    fieldset > legend {
      display: none;
    }

    .MuiFormControl-root > .MuiInputLabel-shrink {
      transform: translate(30px, 7px) scale(1);
      background-color: ${({ background }) => background};
      z-index: 2;
      padding-left: 5px;
      padding-right: 5px;
    }

    .MuiFormControl-root > .MuiInputLabel-shrink.Mui-focused {
      transform: translate(14px, -10px) scale(0.75);

    }.MuiFormControl-root > .MuiInputLabel-shrink.MuiFormLabel-filled {
      transform: translate(14px, -10px) scale(0.75);
    }
  }


`;
export const StyledPanelText = styled.div `
  cursor: default;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(4)}`};

  &.preview-panel--rule-trigger {
    color: ${({ theme }) => theme.palette.accent.main};
  }
`;
export const StyledPreviewResultsContainer = styled.div `
  min-height: 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
`;
export const StyledPerformSearchButton = styled(Button) `
  height: ${({ theme }) => theme.spacing(4.5)};
  white-space: nowrap;
`;
export const StyledOriginalRuleNotification = styled.ul `
  cursor: default;
  margin: ${({ theme }) => theme.spacing(0, 0, 0, 3)};
  padding: 0;
  white-space: nowrap;
`;
export const StyledRuleTriggerNotification = styled.div `
  border: 1px ${baseColors.primaryBlueAccent100} solid;
  border-radius: 8px;
  height: ${({ theme }) => theme.spacing(7)};
  display: flex;
  flex-basis: 100%;
  align-items: center;
  background-color: ${baseColors.primaryBlueAccent050};

  .MuiSvgIcon-root {
    fill: ${baseColors.primaryBlueAccent500};
    padding: ${({ theme }) => theme.spacing(1.5)};
  }
`;
export const StyledAppliedBias = styled.span `
  font-weight: 700
`;
export const StyledAppliedRule = styled.span `
  color: ${baseColors.primaryGreen300};
  text-decoration-line: underline;
  font-weight: 700;

  cursor: pointer;
  .MuiTypography-root {
    cursor: pointer;
  }
`;
export const StyledNoDataFallback = styled(NoDataFallback) `
  margin: ${({ theme }) => theme.spacing(8, 4, 4, 4)};
  b {
    margin-left: 5px;
  }
`;
export const StyledViewCustimizeViewButton = styled(Button) `
  margin-left: 10px;;
  height: 36px;

  && {
    .view-customize-icon {
      transform: rotate(180deg);
    }
  }
`;
export const StyledPopper = styled(Popper) `
  && {

    ${({ isTriggered }) => isTriggered && `
      .MuiAutocomplete-paper {
        padding: 0;
      }

      .MuiAutocomplete-noOptions {
        display: none;
      }
    `}


  }
`;
